import QRadioCustom from 'src/components/RadioCustom/QRadioCustom.vue'
import Stepper from 'src/components/Stepper/Stepper.vue'
import EstadosBrasileiros from 'src/core/mixin/EstadosBrasileiros'
import NotificacaoMixin from 'src/core/mixin/NotificacaoMixin'
import vTopFilters from 'src/core/filters/vTopFilters'
import VeiculosEntradaModel from 'src/model/veiculo/VeiculosEntradaModel'
import VeiculoCalcularPrecoModel from 'src/model/veiculo/VeiculoCalcularPrecoModel'
import { LocalStorage, clone } from 'quasar'
import MarcaModel from 'src/model/veiculo/MarcaModel'
import ModeloModel from 'src/model/veiculo/ModeloModel'
import VersaoModel from 'src/model/veiculo/VersaoModel'
import CambioModel from 'src/model/veiculo/CambioModel'
import CombustivelModel from 'src/model/veiculo/CombustivelModel'
import ConteudoModel from 'src/model/usuario/ConteudoModel'
import PrecificadorModel from 'src/model/veiculo/PrecificadorModel'
import ConsultaPlacaIcarrosModel from 'src/model/veiculo/ConsultaPlacaIcarrosModel'
import CredereLeadModel from 'src/model/usuario/CredereLeadModel'
// import BitrixDealCarecaModel from 'src/model/usuario/BitrixDealCarecaModel'
import CredereSimulacaoModel from 'src/model/usuario/CredereSimulacaoModel'
import CrederePropostaModel from 'src/model/usuario/CrederePropostaModel'
import BiitrixModel from 'src/model/usuario/BiitrixModel'

export default {
  name: 'FormVeiculoTroca',
  components: {
    QRadioCustom,
    Stepper
  },
  mixins: [NotificacaoMixin, EstadosBrasileiros, vTopFilters],
  props: {
    dados: {},
    veiculoTroca: {
      type: Boolean,
      default: true
    },
    corretora: {
      type: String,
      default: ''
    },
    paymentType: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      steps: ['Sobre o carro', 'Contato'],
      loading: false,
      finalizado: false,
      currentStep: 0,
      enviadoSucesso: false,
      termosAceitos: false,
      optin: false,
      salvandoPasso1: false,
      salvandoPasso2: false,
      salvandoPasso3: false,
      salvandoPasso4: false,
      possuiCarroEntrada: false,
      calculandoPreco: false,
      veiculoQuitado: false,
      carroEntradaQuitado: true,
      placaLocalizada: false,
      limparVeiculoEntrada: true,
      modalConteudo: false,
      tipoConteudo: '',
      conteudo: '',
      carregandoConteudo: false,
      // modelBitrixDealCareca: new BitrixDealCarecaModel(),
      modelBitrixDealCareca: new BiitrixModel(),
      modelCalcularPreco: new VeiculoCalcularPrecoModel(),
      modelVeiculosEntrada: new VeiculosEntradaModel(),
      modelCredereLead: new CredereLeadModel(),
      modelCredereSimulacao: new CredereSimulacaoModel(),
      modelCredereSimulacaoBuscar: new CredereSimulacaoModel(),
      modelCredereProposta: new CrederePropostaModel(),
      resultadosCalcularPreco: null,
      veiculoSaldoDevedor: 0,
      versaoAnoMaximo: 2099,
      versaoAnoMinimo: 2010,
      anoLimite: 2010,
      kmLimite: 120000,
      models: [],
      carregamentos: [],
      listas: [],
      listaVersao: [],
      keys: [],
      thumbStyle: {
        right: '4px',
        borderRadius: '5px',
        backgroundColor: '#76528F',
        width: '5px',
        opacity: 0.75
      },
      listagens: [
        'marca_id',
        'modelo_id',
        'versao_id',
        'cambio_id',
        'combustivel_id'
      ],
      diretivaSemMascara: {
        decimal: ',',
        thousands: '.',
        prefix: 'R$ ',
        suffix: '',
        precision: 0,
        masked: false
      },
      diretivaKm: {
        decimal: ',',
        thousands: '.',
        prefix: '',
        suffix: '',
        precision: 0,
        masked: false
      }
    }
  },
  watch: {
    'veiculoTroca' (agora, anterior) {
      if (agora !== anterior) {
        if (agora) {
          this.currentStep = 0
        } else {
          this.currentStep = 1
        }
      }
    },
    'modelVeiculosEntrada.form.placa' (agora) {
      if (agora && agora.length < 8) {
        this.limparCamposVeiculoEntrada()
      }
      // this.calcularPreco()
    },
    'modelVeiculosEntrada.form.marca_id' (agora, anterior) {
      if (
        agora !== anterior &&
        (anterior !== null || agora === null) &&
        this.limparVeiculoEntrada
      ) {
        if (this.$refs.formCrederePasso1) {
          this.$refs.formCrederePasso1.resetValidation()
        }
        this.listas['versao_id'] = []
        if (LocalStorage.has('veiculo-listas')) {
          let listas = LocalStorage.getItem('veiculo-listas')
          listas['versao_id'] = []
          LocalStorage.set(`veiculo-listas`, listas)
          this.keys['versao_id']++
        }
        let data = {
          modelo_id: null,
          versao_id: null,
          combustivel_id: null,
          cambio_id: null,
          ano_fabricacao: null,
          ano_modelo: null,
          valor_mercado: 0,
          valor_minimo: 0,
          valor_maximo: 0
        }
        // this.modelVeiculosEntrada.form = Object.assign({}, this.modelVeiculosEntrada.form, data)
        this.limparCamposVeiculoEntrada(data)
      }
      this.buscarListagemRequest('modelo_id', false, `marca_id:${agora}`)
    },
    'modelVeiculosEntrada.form.modelo_id' (agora, anterior) {
      if (
        agora !== anterior &&
        (anterior !== null || agora === null) &&
        this.limparVeiculoEntrada
      ) {
        let data = {
          versao_id: null,
          combustivel_id: null,
          cambio_id: null,
          ano_fabricacao: null,
          ano_modelo: null,
          valor_mercado: 0,
          valor_minimo: 0,
          valor_maximo: 0
        }
        // this.modelVeiculosEntrada.form = Object.assign({}, this.modelVeiculosEntrada.form, data)
        this.limparCamposVeiculoEntrada(data)
      }
      this.buscarListagemRequest('versao_id', false, `modelo_id:${agora}`)
    },
    'modelVeiculosEntrada.form.versao_id' (agora, anterior) {
      if (
        agora !== anterior &&
        (anterior !== null || agora === null) &&
        this.limparVeiculoEntrada
      ) {
        // let data = { combustivel_id: null, cambio_id: null, ano_fabricacao: null, ano_modelo: null, valor_mercado: 0, valor_minimo: 0, valor_maximo: 0 }
        let data = {
          combustivel_id: null,
          cambio_id: null,
          valor_mercado: 0,
          valor_minimo: 0,
          valor_maximo: 0
        }
        // this.modelVeiculosEntrada.form = Object.assign({}, this.modelVeiculosEntrada.form, data)
        this.limparCamposVeiculoEntrada(data)
      }
      this.validarAnoVeiculo()
    }
    // 'modelVeiculosEntrada.form.placa_uf' (agora, anterior) {
    //   if (agora !== anterior) {
    //     this.calcularPreco()
    //   }
    // },
    // 'modelVeiculosEntrada.form.ano_fabricacao' (agora, anterior) {
    //   if (agora !== anterior) {
    //     this.calcularPreco()
    //   }
    // },
    // 'modelVeiculosEntrada.form.ano_modelo' (agora, anterior) {
    //   if (agora !== anterior) {
    //     this.calcularPreco()
    //   }
    // }
  },
  mounted: function () {
    if (this.veiculoTroca) {
      this.currentStep = 0
    } else {
      this.currentStep = 1
    }
    if (this.listagens) {
      this.filtrosListagens = this.listagens.reduce((acc, atual) => {
        let buscasPermitidas = [
          'categoria_id',
          'marca_id',
          'modelo_id',
          'versao_id',
          'cor_id'
        ]
        acc[`${atual}`] = {
          filtrado: [],
          termo: '',
          permitirBusca: buscasPermitidas.includes(atual)
        }

        return acc
      }, [])
    }

    // LocalStorage.remove('veiculo-listas')
    if (LocalStorage.has('veiculo-listas')) {
      const listas = LocalStorage.getItem('veiculo-listas')
      this.listas = listas
    } else {
      // buscar todas as listagens
      this.buscarTodasAsListagens()
    }
    this.buscarTodasAsListagens()
    this.$store.commit('depois/putData', this.modelCredereLead.form)

    this.newModelCredereLead()
    this.modelCredereLead.form.birthdate = this.usuarioSelecionado
      ? this.usuarioSelecionado.data_nascimento
      : null
    if (
      this.usuarioSelecionado &&
      this.usuarioSelecionado.documento_lead &&
      this.usuarioSelecionado.documento_lead.credere_lead_id
    ) {
      this.modelCredereLead.form.name = this.usuarioSelecionado.nome
      this.salvandoPasso3 = 'ok'
    }
    this.buscarListagemRequest('marca_id', false)
    if (!this.listagens) {
      this.listagens = []
    }
    this.carregamentos = this.listagens.reduce((acc, atual) => {
      acc[`${atual}`] = false

      return acc
    }, {})
    this.listas = this.listagens.reduce((acc, atual) => {
      acc[`${atual}`] = []
      return acc
    }, {})
    this.keys = this.listagens.reduce((acc, atual, index) => {
      acc[`${atual}`] = index + 1

      return acc
    }, [])
    this.possuiCarroEntrada = true
    this.modelCalcularPreco = new VeiculoCalcularPrecoModel()
  },
  filters: {
    tipoNome (tipo) {
      if (tipo) {
        if (tipo === 'tipo_retomada_id') {
          return 'Origem de entrada'
        }
        let nome = tipo.replace('_id', '')
        if (nome) {
          return nome.charAt(0).toUpperCase() + nome.slice(1)
        } else {
          return ''
        }
      } else {
        return ''
      }
    }
  },
  created () {
    this.models = {
      marca_id: MarcaModel,
      modelo_id: ModeloModel,
      versao_id: VersaoModel,
      cambio_id: CambioModel,
      combustivel_id: CombustivelModel
    }
  },
  computed: {
    avancarPasso1: {
      get: function () {
        return parseFloat(this.modelVeiculosEntrada.form.valor_mercado) > 0
      }
    },
    valorVeiculoComp () {
      return this.dados
        ? this.dados.valor_venda_pf && this.dados.valor_venda_pf > 0
          ? this.dados.valor_venda_pf
          : 0
        : 0
    },
    valorEntradaVeiculoComp: {
      get: function () {
        return this.possuiCarroEntrada
          ? this.veiculoQuitado
            ? parseFloat(this.modelVeiculosEntrada.form.valor_mercado)
            : parseFloat(this.veiculoSaldoDevedor) >=
              parseFloat(this.modelVeiculosEntrada.form.valor_mercado)
              ? 0
              : parseFloat(this.modelVeiculosEntrada.form.valor_mercado) -
              parseFloat(this.veiculoSaldoDevedor)
          : 0
      }
    }
  },
  methods: {
    submitCrederePasso1 () {
      if (this.possuiCarroEntrada) {
        if (this.modelCalcularPreco.form.placa_uf) { this.modelVeiculosEntrada.form.uf_placa = this.modelCalcularPreco.form.placa_uf }
        if (this.modelCalcularPreco.form.km) { this.modelVeiculosEntrada.form.km = this.modelCalcularPreco.form.km.toString() }

        const send = clone(this.modelVeiculosEntrada.form)

        if (this.dados && this.dados.valor_fipe) {
          send.valor_fipe = this.dados.valor_fipe
        }

        // if (!this.veiculoQuitado) {
        //   send.valor_saldo_devedor = this.veiculoSaldoDevedor
        // }
        // if (this.valorVeiculoComp <= this.modelVeiculosEntrada.form.valor_mercado) {
        //   this.notificacao('aviso', 'Valor do veículo de entrada é igual ou superior ao veículo anunciado!', 3000)
        // } else {
        this.salvandoPasso1 = true
        const aviso = this.notificacao('enviando')
        send.simulacao = true
        this.modelVeiculosEntrada
          .salvar(send)
          .then(res => {
            this.modelCredereProposta.form.veiculos_entradas_id =
              res && res.dados && res.dados.id ? res.dados.id : null

            aviso()
            this.notificacao('salvo', 'Salvo com sucesso', 3000)
            this.forcerRender++
            this.salvandoPasso1 = 'ok'
            this.currentStep = 1

            // this.$refs.formCrederePasso3.scrollIntoView({ behavior: 'smooth' })
          })
          .catch(error => {
            aviso()
            this.salvandoPasso1 = false
            this.notificacao('erro', error.msg)
          })
        // }
      } else {
        this.forcerRender++
        this.salvandoPasso1 = 'ok'
        this.currentStep = 1
        // this.$refs.formCrederePasso3.scrollIntoView({ behavior: 'smooth' })
      }
    },
    submitLead () {
      this.loading = true
      const marcaUsado = this.listas['marca_id'][this.listas['marca_id'].findIndex(ind => this.modelVeiculosEntrada.form.marca_id === ind.id)]
      const modeloUsado = this.listas['modelo_id'][this.listas['modelo_id'].findIndex(ind => this.modelVeiculosEntrada.form.modelo_id === ind.id)]
      const versaoUsado = this.listas['versao_id'][this.listas['versao_id'].findIndex(ind => this.modelVeiculosEntrada.form.versao_id === ind.id)]

      this.modelBitrixDealCareca.form.LEAD.NAME = this.modelCredereLead.form.name
        ? this.modelCredereLead.form.name
        : this.usuarioSelecionado && this.usuarioSelecionado.nome
          ? this.usuarioSelecionado.nome
          : 'Sem Nome'
      this.modelBitrixDealCareca.form.LEAD.UF_CRM_1602009023 = this.$options.filters.soNumeros(
        this.modelCredereLead.form.cpf_cnpj
          ? this.modelCredereLead.form.cpf_cnpj
          : this.usuarioSelecionado && this.usuarioSelecionado.documento
            ? this.usuarioSelecionado.documento
            : '1'
      )
      this.modelBitrixDealCareca.form.LEAD.EMAIL[0].VALUE = this
        .modelCredereLead.form.email
        ? this.modelCredereLead.form.email
        : this.usuarioSelecionado && this.usuarioSelecionado.email
          ? this.usuarioSelecionado.email
          : `${this.modelBiitrix.form.LEAD.UF_CRM_1602009023}@netcaros.com.br`
      this.modelBitrixDealCareca.form.LEAD.PHONE[0].VALUE = this.$options.filters.soNumeros(
        this.modelCredereLead.form.phone_number
          ? this.modelCredereLead.form.phone_number
          : this.usuarioSelecionado && this.usuarioSelecionado.celular
            ? this.usuarioSelecionado.celular
            : '1'
      )

      this.modelCalcularPreco.form.valor_fipe = this.modelVeiculosEntrada.form.valor_mercado
      this.modelBitrixDealCareca.form.DEAL.OPPORTUNITY = this.modelVeiculosEntrada.form.valor_mercado.toString()
      this.modelBitrixDealCareca.form.DEAL.UF_CRM_1600859637 = this.modelVeiculosEntrada.form.valor_mercado
      this.modelBitrixDealCareca.form.DEAL.UF_CRM_1600913147 = this.modelVeiculosEntrada.form.valor_mercado
      this.modelBitrixDealCareca.form.DEAL.UF_CRM_1651977119 = window.location.pathname

      let send = clone(this.modelBitrixDealCareca.form)

      send.anuncio_id = parseInt(this.dados.id)
      var gclid = JSON.parse(localStorage.getItem('gclid'))
      if (gclid) {
        send.gclid = gclid.value
      }

      let nomeCompleto = send.LEAD.NAME
      let nomesArr = nomeCompleto.split(' ')

      if (nomesArr[0]) send.LEAD.NAME = nomesArr[0]
      if (nomesArr[1]) {
        send.LEAD.SECOND_NAME = nomesArr[1]
      } else {
        send.LEAD.SECOND_NAME = nomesArr[0]
      }
      if (nomesArr[2]) {
        send.LEAD.LAST_NAME = nomesArr[2]
      } else {
        send.LEAD.LAST_NAME = nomesArr[0]
      }

      if (this.logado) {
        send.bitrix_lead_id = this.logado.bitrix_lead_id
      }
      if (this.dados) {
        send.DEAL.UF_CRM_1600860123 = this.dados.ano_fabricacao.toString() ?? 'Sem Informação'
        send.DEAL.UF_CRM_1687972601 = this.dados.estoque_local.nome ?? 'Sem Informação'
        send.DEAL.UF_CRM_1600913222 = 'Sem Informação' /* Banco financiamento pre aprovado. */
        send.DEAL.UF_CRM_1600866673352 = this.dados.cambio_objeto ? this.dados.cambio_objeto.cambio_nome : 'Sem Informação'
        send.DEAL.UF_CRM_1600866627564 = 'Veículo'
        send.DEAL.UF_CRM_1600866641722 = this.dados.combustivel_objeto ? this.dados.combustivel_objeto.combustivel_nome : 'Sem Informação'
        send.DEAL.UF_CRM_1600912767 = this.dados.cor_objeto ? this.dados.cor_objeto.cor_nome : 'Sem Informação'
        send.DEAL.UF_CRM_1600913257 = this.$options.filters.dateEng() ?? 'Sem Informação'
        send.DEAL.UF_CRM_1600912795 = 'Sem Informação' /* Descrição veiculo para troca */
        send.DEAL.UF_CRM_1600913200 = 'Sim' /* Financiamento pré aprovado (Sim,Não). 'Sim' => 271 | 'Não' => 273 */
        send.DEAL.UF_CRM_1600860088 = process.browser ? window.location.href : 'Não possui Link'
        send.DEAL.UF_CRM_1600860229 = this.dados.marca_objeto && this.dados.marca_objeto.marca_nome ? this.dados.marca_objeto.marca_nome : 'Sem Informação'
        send.DEAL.UF_CRM_1601578991 = this.dados.marca_objeto && this.dados.marca_objeto.marca_nome ? this.dados.marca_objeto.marca_nome : 'Sem Informação'
        send.DEAL.UF_CRM_1600860141 = this.dados.modelo_objeto && this.dados.modelo_objeto.modelo_nome ? this.dados.modelo_objeto.modelo_nome : 'Sem Informação'
        send.DEAL.UF_CRM_1600912756 = this.dados.versao_objeto && this.dados.versao_objeto.versao_nome ? this.dados.versao_objeto.versao_nome : 'Sem Informação'
        send.DEAL.UF_CRM_1600912782 = this.dados.acessorios_busca_nome ? this.dados.acessorios_busca_nome.join(', ') : 'Não possui Acessórios'
        send.DEAL.UF_CRM_1600860032 = this.dados.placa ?? 'Sem Informação'
        send.DEAL.UF_CRM_1600912818 = this.dados ? this.valorVeiculoComp.toString() : '0'

        send.DEAL.UF_CRM_1602179548 = this.veiculoTroca ? 'SIM' : 'NÃO'
        send.DEAL.UF_CRM_1600859514 = this.paymentType
        send.DEAL.UF_CRM_1606076503 = this.veiculoTroca ? 831 : 833
        send.DEAL.UF_CRM_1611422640206 = this.veiculoTroca ? 871 : 873

        // Fotos Veiculo
        if (this.dados.bem_arquivos) {
          this.dados.bem_arquivos.map((img, i) => {
            if (i >= 0) {
              send.DEAL.UF_CRM_1601928279578.push(process.env.arquivosUrl + img.url)
            }
            if (i === 0) {
              send.DEAL.UF_CRM_1603919743536 = process.env.arquivosUrl + img.url.trim('/')
            }
          })
        }
        send.DEAL.UF_CRM_1600861473041 = send.DEAL.UF_CRM_1601928279578.length > 0 ? send.DEAL.UF_CRM_1601928279578.toString() : 'Sem Informação'
        /* */
      }
      if (this.veiculoTroca) {
      // valor de mercado carro
        if (this.resultadosCalcularPreco != null) {
          send.DEAL.UF_CRM_1600859637 = parseInt(this.resultadosCalcularPreco.valor_varejo)
        }
        send.DEAL.UF_CRM_1600913053 = this.modelVeiculosEntrada.form.placa
        send.DEAL.UF_CRM_603A9A8462094 = send.DEAL.UF_CRM_1600913053

        send.DEAL.UF_CRM_1600913067 = marcaUsado
          ? (
            marcaUsado.nome +
            ' Cod.:' +
            this.modelVeiculosEntrada.form.marca_id
          ).toString()
          : ''
        send.DEAL.UF_CRM_603A9A839CA41 = send.DEAL.UF_CRM_1600913067

        send.DEAL.UF_CRM_1600913084 =
        modeloUsado && versaoUsado
          ? (modeloUsado.nome + ' Versão: ' + versaoUsado.nome).toString()
          : ''
        send.DEAL.UF_CRM_603A9A83E7E71 = send.DEAL.UF_CRM_1600913084

        send.DEAL.UF_CRM_1600913084 += (
          ' Cod.:' +
        this.modelVeiculosEntrada.form.modelo_id +
        '-' +
        this.modelVeiculosEntrada.form.versao_id
        ).toString()
        if (this.modelVeiculosEntrada.form.ano_fabricacao) {
          send.DEAL.UF_CRM_1600913106 = this.modelVeiculosEntrada.form.ano_fabricacao.toString()
        }
        send.DEAL.UF_CRM_603A9A843F6F0 = send.DEAL.UF_CRM_1600913106
        // valor pedido carro na troca
        send.DEAL.UF_CRM_1600913147 = parseInt(
          this.modelVeiculosEntrada.form.valor_mercado
        )
        // valor do veiculo na troca
        send.DEAL.UF_CRM_1600912818 = parseInt(
          this.modelVeiculosEntrada.form.valor_mercado
        )
        send.DEAL.UF_CRM_1600912818 = send.DEAL.UF_CRM_1600912818.toString()

        send.DEAL.UF_CRM_603A9A83C0BD5 = this.modelVeiculosEntrada.form.km.toString()

        // DESCRICAO
        send.DEAL.UF_CRM_1600859649 =
        'Ano/Mod: ' +
        (
          this.modelVeiculosEntrada.form.ano_fabricacao +
          '/' +
          this.modelVeiculosEntrada.form.ano_modelo
        ).toString()
        if (
          this.resultadosCalcularPreco &&
        this.resultadosCalcularPreco.valor_varejo
        ) {
          send.DEAL.UF_CRM_1600859649 +=
          '\n. Valor no varejo: ' +
          this.resultadosCalcularPreco.valor_varejo.toLocaleString('pt-BR', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
          })
        }
        send.DEAL.UF_CRM_1600859649 +=
        '\n. Valor carro na troca: ' +
        this.modelVeiculosEntrada.form.valor_mercado.toLocaleString('pt-BR', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2
        })

        send.DEAL.UF_CRM_1600912795 = send.DEAL.UF_CRM_1600859649

        let send1 = clone(this.modelVeiculosEntrada.form)
        send1.quero_vender = 1
        if (this.modelCalcularPreco.form.placa_uf) { send1.uf_placa = this.modelCalcularPreco.form.placa_uf }
        if (this.modelCalcularPreco.form.km) { send1.km = this.modelCalcularPreco.form.km.toString() }
        if (this.usuarioSelecionado) {
          send1.usuario_id = this.usuarioSelecionado.id
        } else {
          send1.nome = this.modelBitrixDealCareca.form.LEAD.NAME
          send1.email = this.modelBitrixDealCareca.form.LEAD.EMAIL[0].VALUE
          send1.documento = this.modelBitrixDealCareca.form.LEAD.UF_CRM_1602009023
          send1.celular = this.modelBitrixDealCareca.form.LEAD.PHONE[0].VALUE
        }

        this.modelVeiculosEntrada
          .salvar(send1)
          .then(res => {
            this.modelBitrixDealCareca
              .salvar(send)
              .then(res => {
                this.finalizado = true
                this.loading = false
                this.salvando = false
                this.enviadoSucesso = true
                this.currentStep = 0
                this.modelVeiculosEntrada.form = {}
                this.modelCalcularPreco.form = {}
                this.resultadosCalcularPreco = null
                this.limparCamposVeiculoEntrada()
              })
              .catch(error => {
                this.loading = false
                this.salvando = false
                this.notificacao('erro', error.msg)
              })
          })
          .catch(error => {
            this.loading = false
            this.salvando = false
            this.notificacao('erro', error.msg)
          })
      } else {
        this.modelBitrixDealCareca
          .salvar(send)
          .then(res => {
            this.finalizado = true
            this.loading = false
            this.salvando = false
            this.enviadoSucesso = true
            this.currentStep = 0
            this.modelVeiculosEntrada.form = {}
            this.modelCalcularPreco.form = {}
            this.resultadosCalcularPreco = null
            this.limparCamposVeiculoEntrada()
          })
          .catch(error => {
            this.loading = false
            this.salvando = false
            this.notificacao('erro', error.msg)
          })
      }
    },
    placaResponse (res) {
      if (res) {
        this.limparCamposVeiculoEntrada()
        this.placaLocalizada = true
        this.limparVeiculoEntrada = false
        let camposResponse = clone(res)
        delete camposResponse['situacao_bem']
        let dados = res.dados_consulta
        delete dados.motor

        let camposGenericos = Object.keys(camposResponse).filter(
          campo => campo !== 'dados_consulta'
        )
        camposGenericos.map(campo => {
          this.modelVeiculosEntrada.form[`${campo}_id`] =
            camposResponse[campo].id
        })
        this.modelVeiculosEntrada.form.cambio_id =
          dados['cambioAutomatico'] === 0 ? 2 : 1

        if (
          camposResponse &&
          camposResponse.versao_precificadores &&
          camposResponse.versao_precificadores.length > 0
        ) {
          this.modelCalcularPreco.form.valor_fipe = this.$options.filters
            .soNumeros(camposResponse.versao_precificadores[0].valor)
            .toString()
        }

        if (camposResponse && camposResponse.marca) {
          this.modelVeiculosEntrada.form.marca_nome = camposResponse.marca.nome
        }
        Object.keys(dados).map(campo => {
          switch (campo) {
            case 'marcaId':
              this.modelVeiculosEntrada.form.marca_id = dados[`${campo}`]
              break
            case 'anoFabricacao':
              this.modelVeiculosEntrada.form.ano_fabricacao = dados[`${campo}`]
              break
            case 'anoModelo':
              this.modelVeiculosEntrada.form.ano_modelo = dados[`${campo}`]
              break
            case 'combustivelId':
              this.modelVeiculosEntrada.form.combustivel_id = dados[`${campo}`]
              break
            default:
              break
          }
        })
        setTimeout(() => {
          this.limparVeiculoEntrada = true
        }, 3000)
      }
    },
    limparCamposVeiculoEntrada (dados = null) {
      this.resultadosCalcularPreco = null
      if (dados === null) {
        // this.modelCalcularPreco.form.km = ''
        // this.modelCalcularPreco.form.placa_uf = ''
        this.modelVeiculosEntrada.form.marca_id = null
        this.modelVeiculosEntrada.form.modelo_id = null
        this.modelVeiculosEntrada.form.versao_id = null
        // this.modelVeiculosEntrada.form.cambio_id = null
        // this.modelVeiculosEntrada.form.combustivel_id = null
        // this.modelVeiculosEntrada.form.ano_fabricacao = null
        // this.modelVeiculosEntrada.form.ano_modelo = null
        this.modelVeiculosEntrada.form.valor_mercado = 0
        this.modelVeiculosEntrada.form.valor_maximo = 0
        this.modelVeiculosEntrada.form.valor_minimo = 0
        if (this.$refs.formCrederePasso1) {
          this.$refs.formCrederePasso1.resetValidation()
        }
      } else {
        Object.keys(dados).map(res => {
          this.modelVeiculosEntrada.form[`${res}`] = ''
        })
      }
    },
    newModelCredereLead () {
      this.modelCredereLead = new CredereLeadModel(this.usuarioSelecionado)
      if (this.$refs.formCrederePasso3) {
        this.$refs.formCrederePasso3.reset()
      }
    },
    // Methods Listagem
    buscarTodasAsListagens () {
      this.buscandoTodasAsListagens = true
      let promises = this.listagens.map(tipo => {
        if (tipo !== 'modelo_id' && tipo !== 'versao_id') {
          let model = new this.models[tipo]()
          return model.getListagem({ params: { limit: 1000 } })
        } else {
          return new Promise(resolve => {
            resolve(true)
          })
        }
      })
      Promise.all(promises)
        .then(response => {
          this.listagens.forEach((tipo, index) => {
            this.listas[tipo] = response[index].dados ?? []
          })
          // let listas = this.listas
          // if (LocalStorage.has('veiculo-listas')) {
          //   listas = LocalStorage.getItem('veiculo-listas')
          //   listas = [...listas, ...this.listas]
          // }
          LocalStorage.set('veiculo-listas', this.listas)
          this.buscandoTodasAsListagens = false
        })
        .catch(() => {
          this.buscandoTodasAsListagens = false
        })
    },
    removeDuplicates (myArr, prop, incluir = null) {
      return myArr.filter((obj, pos, arr) => {
        if (incluir !== null) {
          return (
            arr.map(mapObj => mapObj[prop]).indexOf(obj[prop]) === pos ||
            incluir === obj['id']
          )
        } else {
          return arr.map(mapObj => mapObj[prop]).indexOf(obj[prop]) === pos
        }
      })
    },
    buscarListagemRequest (tipo, promise, search = '', relacao = false) {
      let model = new this.models[`${tipo}`](relacao)
      this.carregamentos[`${tipo}`] = true
      if (promise) {
        return model.getListagem({
          params: {
            search,
            limit: 1000,
            orderBy: relacao ? '' : 'nome',
            sortedBy: 'asc'
          }
        })
      } else {
        model
          .getListagem({
            params: { search, limit: 1000, orderBy: 'nome', sortedBy: 'asc' }
          })
          .then(response => {
            this.carregamentos[`${tipo}`] = false
            if (tipo === 'versao_id') {
              this.listaVersao = clone(response.dados)
              let data = []
              data[1] = this.removeDuplicates(
                response.dados,
                'nome',
                this.modelVeiculosEntrada.form.versao_id
              )
              response.dados = data[1]
            }
            if (
              this.modelVeiculosEntrada.form[`${tipo}`] > 0 &&
              response.dados.findIndex(
                ind => this.modelVeiculosEntrada.form[`${tipo}`] === ind.id
              ) === -1
            ) {
              this.modelVeiculosEntrada.form[`${tipo}`] = ''
            }
            this.listas[`${tipo}`] = response.dados ?? []

            if (LocalStorage.has('veiculo-listas')) {
              let listas = LocalStorage.getItem('veiculo-listas')
              listas[`${tipo}`] = response.dados
              LocalStorage.set(`veiculo-listas`, listas)
              this.keys[`${tipo}`]++
              if (tipo === 'versao_id') {
                this.validarAnoVeiculo()
              }
            }
            if (this.$refs.formCrederePasso1) {
              this.$refs.formCrederePasso1.resetValidation()
            }
          })
          .catch(() => {
            this.carregamentos[`${tipo}`] = false
          })
      }
    },
    validarAnoVeiculo () {
      if (
        this.listaVersao &&
        this.listaVersao.length > 0 &&
        this.modelVeiculosEntrada.form.versao_id > 0
      ) {
        // onst versaoUsado = this.listas['versao_id'][this.listas['versao_id'].findIndex(ind => this.modelVeiculosEntrada.form.versao_id === ind.id)]
        // if (versaoUsado) {
        //   let versoes = clone(this.listaVersao)
        //   this.versaoAnoMinimo = versoes.filter((obj) => {
        //     return versaoUsado.nome === obj.nome
        //   }).reduce((a, b) => a < b.ano_inicio ? a : b.ano_inicio, 10000)
        //   versoes = clone(this.listaVersao)
        //   this.versaoAnoMaximo = versoes.filter((obj) => {
        //     return versaoUsado.nome === obj.nome
        //   }).reduce((a, b) => a > b.ano_final ? a : b.ano_final, 0)
        // }
        this.$refs.ano_fabricacao.validate()
        this.$refs.ano_modelo.validate()
      }
    },
    consultarPlaca (placa = '') {
      this.placaLocalizada = false
      this.resultadosCalcularPreco = null
      // eslint-disable-next-line
      placa = placa ? placa.replace(/[\s-]/g, "").replace("-", "") : null;
      if (placa && placa.length > 6) {
        const modelPlaca = new ConsultaPlacaIcarrosModel()
        this.notificacao('enviando', 'Consultando placa, aguarde.')
        modelPlaca
          .getHashedId(placa)
          .then(res => {
            if (res.erro === 1) {
              this.notificacao(
                'aviso',
                'Veículo não encontrado, preencha as informações do veiculo manualmente'
              )
            } else {
              this.placaResponse(res.dados)
            }
          })
          .catch(() => {
            this.notificacao(
              'aviso',
              'Veículo não encontrado, preencha as informações do veiculo manualmente'
            )
          })
      }
    },
    calcularPreco () {
      this.$refs.formCrederePasso1.validate().then(success => {
        if (success) {
          let fipe = this.listas['versao_id'][this.listas['versao_id'].findIndex(ind => this.modelVeiculosEntrada.form.versao_id === ind.id)].codigo_fipe

          if (fipe === undefined) {
            fipe = 1
          } else {
            this.modelCalcularPreco.form.valor_fipe = null
          }

          const model = new PrecificadorModel()
          model
            .getListagem({
              params: {
                search: `codigo:${fipe};ano:${this.modelVeiculosEntrada.form.ano_modelo}`,
                searchJoin: 'and',
                orderBy: 'id',
                sortedBy: 'desc'
              }
            })
            .then(response => {
              this.calculandoPreco = true
              const aviso = this.notificacao('enviando')

              const send = clone(this.modelCalcularPreco.form)
              send.placa = this.modelVeiculosEntrada.form.placa
              send.marca_id = this.modelVeiculosEntrada.form.marca_id
              send.modelo_id = this.modelVeiculosEntrada.form.modelo_id
              send.ano_modelo = this.modelVeiculosEntrada.form.ano_modelo
              send.versao_id = this.modelVeiculosEntrada.form.versao_id
              send.combustivel_id = this.modelVeiculosEntrada.form.combustivel_id

              if (
                !this.modelCalcularPreco.form.valor_fipe ||
                !this.placaLocalizada
              ) {
                if (
                  response &&
                  response.dados &&
                  response.dados[0] &&
                  response.dados[0].valor
                ) {
                  this.modelCalcularPreco.form.valor_fipe = response.dados[0].valor.replace(
                    /\D/g,
                    ''
                  )
                  send.valor_fipe = response.dados[0].valor.replace(/\D/g, '')
                } else {
                  // this.modelCalcularPreco.form.valor_fipe = 20000
                  // send.valor_fipe = 20000
                  aviso()
                  this.calculandoPreco = false
                  send.valor_fipe = 0
                  this.modelVeiculosEntrada.form.valor_mercado = 0
                  this.modelVeiculosEntrada.form.valor_maximo = 0
                  this.modelVeiculosEntrada.form.valor_minimo = 0
                  this.modelVeiculosEntrada.form.valor_varejo = 0
                  this.resultadosCalcularPreco = null
                  // this.notificacao(
                  //   'erro',
                  //   'Não encontramos o veículo. Verifique os dados'
                  // )
                  return
                }
              }

              this.modelCalcularPreco.form = send

              this.modelCalcularPreco
                .getListagem({ params: send })
                .then(res => {
                  const r = res && res.dados ? res.dados : null

                  if (r) {
                    this.modelVeiculosEntrada.form.valor_maximo = parseInt(
                      r.valor_maximo_final
                    )
                    this.modelVeiculosEntrada.form.valor_minimo = parseInt(
                      r.valor_minimo_final
                    )
                    this.modelVeiculosEntrada.form.valor_mercado = parseInt(
                      r.valor_trade_in
                    )
                    this.modelVeiculosEntrada.form.valor_varejo = parseInt(
                      r.valor_price_guru
                    )
                    this.resultadosCalcularPreco = r
                  }

                  aviso()
                  this.notificacao('salvo', 'Calculado com sucesso', 3000)
                  this.calculandoPreco = false
                })
                .catch(error => {
                  aviso()
                  this.calculandoPreco = false
                  this.notificacao('erro', error.msg)
                })
            })
            .catch(() => {
              this.forceRender++
            })
        } else {
          this.notificacao('erro', 'Todos os campos obrigatórios')
        }
      })
    },
    validarCPF (cpf) {
      cpf = this.$options.filters.soNumeros(cpf)
      return cpf && cpf.length >= 11
        ? this.validarCPFCalculo(cpf.toString())
        : null
    },

    validarCPFCalculo (strCPF) {
      var Soma = 0
      var Resto = null
      if (strCPF === '00000000000') return false

      for (let i = 1; i <= 9; i++) { Soma = Soma + parseInt(strCPF.substring(i - 1, i)) * (11 - i) }
      Resto = (Soma * 10) % 11

      if (Resto === 10 || Resto === 11) Resto = 0
      if (Resto !== parseInt(strCPF.substring(9, 10))) return false

      Soma = 0
      for (let i = 1; i <= 10; i++) { Soma = Soma + parseInt(strCPF.substring(i - 1, i)) * (12 - i) }
      Resto = (Soma * 10) % 11

      if (Resto === 10 || Resto === 11) Resto = 0
      if (Resto !== parseInt(strCPF.substring(10, 11))) return false
      return true
    },

    validarEmail (email) {
      // eslint-disable-next-line no-useless-escape
      const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      return re.test(String(email).toLowerCase())
    },
    abrirModal (tipo) {
      this.modalConteudo = true
      switch (tipo) {
        case 'termos':
          this.tipoConteudo = 'Termos de Uso'
          this.buscarConteudo('regulamento-termos-de-uso')
          break
        case 'privacidade':
          this.tipoConteudo = 'Política de Privacidade'
          this.buscarConteudo('regulamento-politica-privacidade')
          break
        default:
          this.buscarConteudo('regulamento-politica-privacidade')
          break
      }
    },
    buscarConteudo (chave) {
      const model = new ConteudoModel()
      this.carregandoConteudo = true
      model
        .getListagem({ params: { search: `chave_interna:${chave}` } })
        .then(response => {
          this.conteudo = response.dados[0].conteudo
          this.carregandoConteudo = false
        })
        .catch(error => {
          this.notificacao('erro', error.msg)
          this.modalConteudo = false
          this.carregandoConteudo = false
        })
    }
  }
}
