import { render, staticRenderFns } from "./FormVeiculoTroca2.vue?vue&type=template&id=2523f451&"
import script from "./FormVeiculoTroca2.js?vue&type=script&lang=js&"
export * from "./FormVeiculoTroca2.js?vue&type=script&lang=js&"
import style0 from "./FormVeiculoTroca2.vue?vue&type=style&index=0&lang=sass&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
import QForm from 'quasar/src/components/form/QForm.js';
import QInput from 'quasar/src/components/input/QInput.js';
import QField from 'quasar/src/components/field/QField.js';
import QSelect from 'quasar/src/components/select/QSelect.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import QItem from 'quasar/src/components/item/QItem.js';
import QItemSection from 'quasar/src/components/item/QItemSection.js';
import QTooltip from 'quasar/src/components/tooltip/QTooltip.js';
import QCircularProgress from 'quasar/src/components/circular-progress/QCircularProgress.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QCheckbox from 'quasar/src/components/checkbox/QCheckbox.js';
import QDialog from 'quasar/src/components/dialog/QDialog.js';
import QCard from 'quasar/src/components/card/QCard.js';
import QCardSection from 'quasar/src/components/card/QCardSection.js';
import QScrollArea from 'quasar/src/components/scroll-area/QScrollArea.js';
import qInstall from "../../../node_modules/@quasar/app/lib/webpack/runtime.auto-import.js";
qInstall(component, 'components', {QForm,QInput,QField,QSelect,QIcon,QItem,QItemSection,QTooltip,QCircularProgress,QBtn,QCheckbox,QDialog,QCard,QCardSection,QScrollArea});
